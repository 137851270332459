// 送信ボタンの連打防止
$(document).on('click', '.submitBtn', function () {
    $(this).prop('disabled', true);
    const form = $(this).closest('form');

    const action = $(this).data('action');
    if (action) {
        form.attr('action', action);
    }
    form.submit();
})
